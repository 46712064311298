<template>
  <base-layout>
    <details-page-title
      title="Organization"
      dropdown-text-button="Org Status"
      :details-id="id"
      :status="getStatus"
      @activate="UpdateStatus('A')"
      @deactivate="UpdateStatus('D')"
    >
      <status-dropdown
        :endpoint="getStatusEndpoint"
        method="PATCH"
        :statusCurrent="getStatus"
        statusKeyActivate="status"
        statusValueActivate="A"
        statusKeyDeactivate="status"
        statusValueDeactivate="D"
      />
    </details-page-title>

    <details-page-header
      :name="getName"
      :badge="getBadge"
      :phone="getPhone"
      :email="getEmail"
      :website="getWebsite"
      :tabs="tabs"
      :showCreditInfo="true"
    />

    <!-- <div class="min-h-screen"> -->
    <router-view />
    <!-- </div> -->
  </base-layout>
</template>

<script>
import { StatusConfig } from '@/config/StatusConfig'
import { OrganizationConfig } from '@/config/OrganizationConfig'

import BaseLayout from '@/views/shared/BaseLayout.vue'

import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import DetailsPageHeader from '@/components/ui/DetailsPageHeader.vue'
import StatusDropdown from '@/components/dropdown/StatusDropdown'

export default {
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    StatusDropdown,
  },
  data() {
    return {
      header: {
        name: '',
        city: {
          name: '',
        },
        email_adddress: '',
        phone_number: '',
        website_link: '',
        avatar: '',
        status_active: false,
      },
      tabs: [
        {
          text: 'Profile',
          href: this.getTabHref(`profile`),
        },
        {
          text: 'Pricing Plan',
          href: this.getTabHref(`pricing-plan`),
        },
        {
          text: 'Service Area',
          href: this.getTabHref(`service-areas`),
        },
        {
          text: 'Fleets',
          href: this.getTabHref(`fleets`),
        },
        {
          text: 'Vehicles',
          href: this.getTabHref(`vehicles`),
        },
        {
          text: 'Rents',
          href: this.getTabHref(`rents`),
        },
        {
          text: 'Revenues',
          href: this.getTabHref(`revenues`),
        },
        {
          text: 'Payments',
          href: this.getTabHref(`payments`),
        },
        {
          text: 'Riders',
          href: this.getTabHref(`riders`),
        },
        {
          text: 'Subscription Invoices',
          href: this.getTabHref(`subscription-invoices`),
        },
      ],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getName() {
      return this.header ? this.header.name : ''
    },
    getBadge() {
      return this.header && this.header.city ? this.header.city.name : ''
    },
    getPhone() {
      return this.header ? this.header.phone_number : ''
    },
    getEmail() {
      return this.header ? this.header.email_address : ''
    },
    getWebsite() {
      return this.header ? this.header.website_link : ''
    },
    getStatus() {
      return this.header && this.header.status === 'A' ? 'A' : 'D'
    },
    getStatusEndpoint() {
      return StatusConfig.api.organization(this.id)
    },
  },
  async created() {
    await this.$http
      .get(OrganizationConfig.api.single(this.id))
      .then((res) => {
        console.log('org-header', res)
        this.header = res.data
      })
      .catch((err) => {
        console.log('org-header-err', err)
      })
  },
  methods: {
    goBack() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('/organizations')
    },
    getTabHref(path) {
      return `/organizations/${this.$route.params.id}/${path}`
    },
  },
}
</script>

<style></style>
